import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ImageGridWithZoom from "../components/image-grid-zoom"

const BranchSelectorBoxes = () => {
  const data = useStaticQuery(graphql`
    query {
      configImage: file(relativePath: { regex: "/VRV BSB Configuration/i" }) {
        ...fluidImage
      }
      singleLayoutImage: file(
        relativePath: { regex: "/VRV BSB Example Layout Single Ports/i" }
      ) {
        ...fluidImage
      }
      multiLayoutImage: file(
        relativePath: { regex: "/VRV BSB Example Layout Multi Ports/i" }
      ) {
        ...fluidImage
      }
    }
  `)

  return (
    <div className="uk-container uk-section-xsmall">
      <ImageGridWithZoom
        images={[data.configImage]}
        title={"What is a branch selector box?"}
        description="Branch selector boxes (BSB) are used only with simultaneous heating
              and cooling heat recovery systems. Three pipes (suction gas, liquid,
              high/low pressure gas) are run from the condensing unit to the BSB.
              Two pipes are then run from the BSB to the indoor unit(s). Please see
              the image below for an example configuration."
      ></ImageGridWithZoom>

      <ImageGridWithZoom
        images={[data.singleLayoutImage, data.multiLayoutImage]}
        labels={["Mid rise residential", "Low rise commercial"]}
        subLabels={["With Single Ports", "With Multi Ports"]}
        title={""}
      ></ImageGridWithZoom>

      <div className="uk-section-xsmall">
        <h3 className="uk-text-bold uk-text-center">
          Single Ports VS Multi Ports
        </h3>
        <div className="uk-overflow-auto">
          <table className="uk-table uk-table-striped uk-text-center uk-table-small">
            <thead className="thead-primary uk-text-center">
              <tr>
                <th>Criteria</th>
                <th>Mini Single Ports</th>
                <th>Slim Pipe-Through Flex Multi-Ports</th>
                <th>Standard Multi-Ports</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="uk-text-left">Number of Ports</td>
                <td>1</td>
                <td>4, 6, 8</td>
                <td>10, 12</td>
              </tr>
              <tr>
                <td className="uk-text-left">MBH per Port</td>
                <td>96</td>
                <td>54</td>
                <td>54</td>
              </tr>
              <tr>
                <td className="uk-text-left">Technical Cooling</td>
                <td>Yes*</td>
                <td>Yes*</td>
                <td>No</td>
              </tr>
              <tr>
                <td className="uk-text-left">Drain Connection Needed</td>
                <td>No</td>
                <td>No</td>
                <td>No</td>
              </tr>
              <tr>
                <td className="uk-text-left">Re-positional electrical box</td>
                <td>Yes</td>
                <td>Yes</td>
                <td>No</td>
              </tr>
            </tbody>
          </table>
          <div className="uk-text-meta">
            * Technical cooling allows for connected indoor units to cool down
            to -4F. See technical cooling design guide for more details.
          </div>
        </div>
      </div>
    </div>
  )
}

BranchSelectorBoxes.propTypes = {}

export default BranchSelectorBoxes
