import { Link, navigate } from "gatsby"
import _ from "lodash"
import PropTypes from "prop-types"
import React, { useState } from "react"
import UIkit from "uikit"
import Card from "./card"

const SwitcherWrapper = ({ children, enabled }) => (
  <ul className={enabled ? 'uk-switcher' : ''}>{children}</ul>
)

const ProductsGroups = ({
  items,
  category,
  multiSelect,
  productDescription,
  groupByRefrigerant = true
}) => {
  const [selectedItem, setSelectedItem] = useState(null)

  const handleClick = (group, item, items) => {
    item.group = group.name
    if ((selectedItem || {})._id === item._id) {
      setSelectedItem(null)
      return
    }
    if (!selectedItem || (selectedItem || {}).group === item.group) {
      setSelectedItem(item)
      UIkit.notification.closeAll()
      UIkit.notification(
        `Product selected from ${group.name}. Now select a product from ${(items.find(_group => _group.name !== group.name) || {}).name
        }.`,
        { pos: "bottom-center", status: "success" }
      )
    } else {
      UIkit.notification.closeAll()
      navigate(
        `/category/${category._id}/products/${selectedItem.group.toLowerCase().includes("outdoor")
          ? `${selectedItem._id}+${item._id}`
          : `${item._id}+${selectedItem._id}`
        }`
      )
    }
  }

  let groups = groupByRefrigerant ? _.groupBy(items, "refrigerantSeries") : { "all": items };
  groups = Object.keys(groups).map(key => {
    const grouped = _.groupBy(groups[key], "subcategory")
    const items = Object.keys(grouped).map(key => ({
      id: key.replace(/\s/g, "-"),
      name: key,
      description:
        productDescription ? grouped[key].length && grouped[key][0].description : '',
      items: grouped[key],
    }))
    return ({
      id: key.replace(/\s/g, "-"),
      name: key,
      items,
    })
  }).sort((a, b) => a.name.localeCompare(b.name))

  const foundR32 = groups.find(group => group.name.toLowerCase() === "r-32")
  if (!foundR32 && groupByRefrigerant) {
    groups.unshift({
      id: "r-32",
      name: "R-32",
      items: [],
      description: "Coming Soon..."
    })
  }



  return (
    <div className="product-groups">
      {groups.length > 0 && groupByRefrigerant ? (<div data-uk-switcher={`toggle: > *; active: ${!foundR32 ? 1 : 0}`} className="button-list">{groups.map((group) => (<a key={group.id} className="uk-button round outline">{group.name}</a>))}</div>) : <></>}
      <SwitcherWrapper enabled={groups.length > 0 && groupByRefrigerant}>
        {groups.map((group) => {
          return (
            <div key={`group_${group.id}`} className="uk-section-small">
              {group.description ? (
                <p className="description">{group.description}</p>
              ) : (
                <></>
              )}
              {group.items.map((item) => (
                <div key={`sgroup_${item.id}`} className="uk-section-xsmall">
                  <h2 className="line-break">
                    {item.name}{" "}
                    {multiSelect && (selectedItem || {}).group !== item.name && (
                      <span
                        style={{ position: "absolute", marginLeft: "20px" }}
                        className="uk-badge blink"
                      >
                        Select One
                      </span>
                    )}
                  </h2>
                  {item.description ? (
                    <p className="description">{item.description}</p>
                  ) : (
                    <></>
                  )}
                  <div
                    className="uk-grid-match uk-flex uk-flex-wrap uk-flex-center"
                    data-uk-grid
                  >
                    {item.items.map((subItem, i, arr) => {
                      return multiSelect ? (
                        <div
                          key={`product__${subItem._id}`}
                          className={`uk-link-reset uk-pointer card-wrapper uk-width-1-${arr.length
                            } ${selectedItem && selectedItem._id === subItem._id
                              ? "selected"
                              : ""
                            }`}
                          style={{ maxWidth: "400px", minWidth: "280px" }}
                          role="button"
                          tabIndex={i}
                          onClick={handleClick.bind(this, item, subItem, group.items)}
                        >
                          <Card item={subItem} />
                        </div>
                      ) : (
                        <Link
                          to={`/category/${category._id}/products/${subItem._id}`}
                          key={`product__${subItem._id}`}
                          className={`uk-link-reset uk-width-1-${arr.length}`}
                          style={{ maxWidth: "400px", minWidth: "280px" }}
                        >
                          <Card item={subItem} />
                        </Link>
                      )
                    })}
                  </div>
                </div>
              ))}
            </div>
          )
        })}
      </SwitcherWrapper>
    </div>
  )
}

ProductsGroups.propTypes = {
  items: PropTypes.array,
  category: PropTypes.object,
  multiSelect: PropTypes.bool,
  productDescription: PropTypes.bool,
}

export default ProductsGroups
