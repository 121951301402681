import log from "loglevel"
import React from "react"

import { graphql } from "gatsby"
import BranchSelectorBoxesComponent from "../components/branch-selector-boxes"
import ButtonList from "../components/button-list"
import FAQs from "../components/faqs"
import Layout from "../components/layout"
import ProductsGroups from "../components/product-groups"
import ProductsComponent from "../components/products"

const Category = ({ data, pageContext }) => {
  const category = pageContext.category
  const subcategories = pageContext.category.subcategories
  const selectedSubcategory = pageContext.subcategory
  const faqs = data.faqs.nodes || []
  const products = data.products.nodes || []

  log.info(pageContext, products, faqs, data.images)

  let productGroups = false
  let isBSB = false
  let isThermostats = false
  let productSelection = false
  let description = ""
  let productDescriptionExists = false

  products.forEach(product => {
    if (product.description && product.description !== description) {
      if (description) {
        productDescriptionExists = true
      }
      description = product.description
    }
    if ((product.subcategory || "").length > 0) {
      productGroups = true
    }

    if ((product.category || "").toLowerCase() === "branch selector boxes") {
      isBSB = true
    }
    if ((product.category || "").toLowerCase() === "thermostats") {
      isThermostats = true
    }

    product.image = (
      (
        data.images.edges.find(
          edge =>
            edge.node.parent.name.includes(
              product.productSeries.replace("/", ":")
            ) &&
            edge.node.parent.name.includes(product.subcategory.toLowerCase()) &&
            edge.node.parent.name.toLowerCase().includes("landing")
        ) || {}
      ).node || {}
    ).parent
    if (!product.image) {
      product.image = (
        (
          data.images.edges.find(
            edge =>
              edge.node.parent.name.includes(
                product.productSeries.replace("/", ":")
              ) && edge.node.parent.name.toLowerCase().includes("landing")
          ) || {}
        ).node || {}
      ).parent
    }
    if (!product.image) {
      product.image = (
        (
          data.images.edges.find(edge =>
            edge.node.parent.name.includes(
              product.productSeries.replace("/", ":")
            )
          ) || {}
        ).node || {}
      ).parent
    }
  })
  if (selectedSubcategory) {
    if ((selectedSubcategory.name || "").toLowerCase().includes("skyair")) {
      productSelection = true
    }
  }
  return (
    <Layout title={`Daikin ${category.name}`} navItems={pageContext.categories}>
      <div className="uk-section-small">
        <div className="uk-container uk-container-large uk-text-center">
          <h1 className="uk-text-center">{`Daikin ${category.name}`}</h1>
          <ButtonList
            items={subcategories}
            parentId={category._id}
            selectedItemId={selectedSubcategory._id}
          ></ButtonList>
          {!productDescriptionExists && selectedSubcategory.description ? (
            <p className="description">{selectedSubcategory.description}</p>
          ) : (
            <></>
          )}
          {productGroups ? (
            <ProductsGroups
              items={products || []}
              category={category}
              multiSelect={productSelection}
              productDescription={productDescriptionExists}
              groupByRefrigerant={!isThermostats}
            />
          ) : (
            <ProductsComponent
              items={products || []}
              category={category}
              groupByRefrigerant={!isThermostats}
            />
          )}
          {isBSB ? (
            <BranchSelectorBoxesComponent></BranchSelectorBoxesComponent>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className="uk-container uk-container-large">
        <FAQs items={faqs}></FAQs>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query CategoryProductAndImage(
    $categoryId: String
    $subcategoryId: String
    $categoryRegex: String
  ) {
    faqs: allFaqsJson(
      filter: {
        categoryId: { eq: $categoryId }
        subcategoryId: { eq: $subcategoryId }
      }
    ) {
      ...faqsForCategoryAndSubcategory
    }
    images: allImageSharp(
      filter: { original: { src: { regex: $categoryRegex } } }
      sort: { fields: [original___src], order: ASC }
    ) {
      edges {
        node {
          id
          parent {
            ... on File {
              id
              name
            }
            ...squareFluidImage
          }
        }
      }
    }
    products: allProductsJson(filter: { categoryId: { eq: $subcategoryId } }) {
      nodes {
        _id
        category
        capacity
        capacityText
        name
        productId
        productSeries
        modelNumber
        subcategory
        description
        cardFeatures
        refrigerantSeries
        phaseOutWarning
      }
    }
  }
`

export default Category
